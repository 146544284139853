import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import OurNumbers from "../../Components/OurNumbers/OurNumbers";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const INostriNumeriPage = () => {
  return (
    <>
      <Seo locale="it" page="our figures" />
      <Header lang="it" />
      <HamburgerMenu />
      <OurNumbers lang="it" />
      <Partnership lang="it" />
      <Footer lang="it" />
    </>
  );
};

export default INostriNumeriPage;
